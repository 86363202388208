<template>
    <div class="min-h-full relative  flex flex-col text-base-content">
        <sub-header :backButton="true" class="mb-4" :customCrumbLists="customBreadCumbs" />
        <div class="justify-between flex-grow max-h-full">
            <div class="flex h-full gap-4 flex-1">
                <div class="w-2/12 rounded-lg self-start bg-white shadow flex flex-col gap-x-4">
                    <div @click="selectedTab(nav)" v-for="(nav, idx) in computedNavigations" :key="idx"
                        class="px-4 py-3 transition-all relative duration-200 items-center text-sm font-medium flex gap-3  cursor-pointer"
                        :class="{
                            ' bg-primary-300 hover:bg-primary-200 text-base-content-800': currentTab === nav.nav,
                            'hover:bg-gray-100   hover:text-base-content-600': currentTab !== nav.nav,
                            'border-t border-gray-400 border-0': idx != 0,
                            'bg-gray-100 border-b border-gray-300 text-gray-700 cursor-not-allowed pointer-events-not-allowed hover:bg-transparent hover:text-gray-700 opacity-70' : nav.disabled
                        }">
                        <font-awesome-icon :icon="[nav.iconPrefix || 'fa', nav.icon]" />
                        {{ nav.title }}
                    </div>
                </div>
                <div v-if="currentTab" class="flex-1 relative g ">
                    <div class="p-1">
                        <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col"
                            v-show="currentTab === 'Tax Details'">
                            <div
                                class="p-3 panel-head bg-primary-300 font-semibold text-base-content flex gap-3 justify-between items-center rounded-t-2xl">
                                {{ $t('manage_tax.new_tax') }}
                            </div>
                            <div class="py-24 flex items-center justify-center" v-if="isLoading">
                                <Loader />
                            </div>
                            <div class="p-3" v-else>
                                <FormulateForm #default="{ hasErrors }">
                                    <div class="grid grid-cols-2 gap-x-6">
                                        <FormulateInput class="w-full" :label="$t('manage_tax.tax_name')" :placeholder="$t('manage_tax.tax_name')" type="text"
                                            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                            v-model="taxDetails.tax_name" />
                                        <div class="p-2">
                                            <h1 class="flex gap-2"> {{ $t('manage_tax.rate') }} </h1>
                                            <label class="input-group input-group-md">
                                                <span>%</span>
                                                <input type="text" :placeholder="$t('manage_tax.rate')"
                                                    class="input input-bordered input-md h-10 w-full"
                                                    v-model="taxDetails.rate" @input="handleTaxName($event.target.value)" @keypress="isNumber"/>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-1 gap-x-6">
                                        <FormulateInput class="w-full" :label="$t('manage_tax.tax_description')" :placeholder="$t('manage_tax.tax_description')"
                                            type="text"
                                            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                            v-model="taxDetails.tax_description" />
                                    </div>
                                    <div class="flex justify-end max-w-full">
                                        <Button @click="editMode ? editTaxDetails(hasErrors, 'Assigned Client') : saveTaxDetails(hasErrors, 'Assigned Client')" 
                                            class="btn-primary float-right mt-4" text="Save" :disabled="!changeMadeDetails" />
                                    </div>
                                </FormulateForm>
                            </div>
                        </div>
                        <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col pb-4"
                            v-show="currentTab === 'Assigned Client'">
                            <div
                                class="p-5 panel-head bg-primary-300 font-semibold text-base-content flex gap-3 justify-between items-center rounded-t-2xl">
                                {{ $t(`manage_tax.navigation_labels.assigned_client`) }}
                            </div>
                            <div class="py-24 flex items-center justify-center" v-if="isLoading">
                                <Loader />
                            </div>
                            <div class="px-5 overflow-y" v-else>
                                <div>
                                    <customTable :columns="computedColumns" :rows="row" selectAll searchBar
                                        :handleSelectAll="handleSelect">
                                        <template v-slot:name="props">
                                            <div class="flex items-center gap-2 p-1">
                                                <input 
                                                    type="checkbox" 
                                                    :checked="props.item.tax_assigned" 
                                                    class="checkbox"
                                                    v-model="props.item.tax_assigned" 
                                                    @change="reArrange" />
                                                <span>{{ props.item.name }}</span>
                                            </div>
                                        </template>
                                        <template v-slot:rate="props">
                                            <div class="flex items-center justify-center gap-2 p-1">
                                                <input :disabled="props.item.default" type="text" class="rounded-lg text-center p-1 border w-20 px-2" v-model="props.item.rate"
                                                @input="handleDefault(props.item)" 
                                                :class="props.item.default ? 'disableInput' : ''"
                                                />
                                            </div>
                                        </template>
                                        <template v-slot:default="props">
                                            <div class="flex items-center justify-center gap-2 p-1">
                                                <input type="checkbox" :checked="props.item.default" class="checkbox"
                                                    v-model="props.item.default" @change="defaultTax(props.item)" />
                                            </div>
                                        </template>
                                    </customTable>
                                    <div class="flex justify-end max-w-full">
                                        <Button :disabled="!changeMadeAssingee" @click="assignTax" class="btn-primary float-right mt-4" text="Save" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else
                    class="flex flex-1 bg-white p-3 h-full overflow-y-scroll scroll-bar justify-center items-center">No
                    Options Selected</div>
            </div>
        </div>
    </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import Button from "@/components/button";
import customTable from "@shared/components/custom-table";
import { getTaxDetails, getTenantTax, editTaxDetails, assignTaxTenant, saveTax, getClientList } from "../services";
import Loader from "@shared/loader";
import { isNumber } from "@/plugins/functions"
// End
export default {
    name: "pack-details",
    props: {
        colorHeader: {
            type: String,
            default: '#323648',
        },
        colorRow: {
            type: String,
            default: '#EDF3FF',
        },
    },
    components: {
        SubHeader,
        Button,
        customTable,
        Loader,
    },
    data: () => ({
        changeMadeAssingee: false,
        changeMadeDetails: false,
        navigations: [
            {
                title: "",
                nav: "Tax Details",
                value: "tax_details",
                icon: "circle-check",
                subnav: false,
                iconPrefix: "far",
                disabled: false
            },
            {
                title: "",
                nav: "Assigned Client",
                value: "assigned_client",
                icon: "file-lines",
                subnav: false,
                iconPrefix: "far",
                disabled: true
            },
        ],
        currentTab: "Tax Details",
        customBreadCumbs: [],
        cols: [{
            label: "name",
            type: "custom"
        }, {
            label: "rate",
            type: "custom"
        }, {
            label: "default",
            type: "custom"
        },],
        caseEventDetails: {},
        row: [],
        savedTax: [],
        isLoading: false,
        taxDetails: {
            tax_name: null,
            tax_description: null,
            rate: null
        },
        tenantId:'',
        taxId: null
    }),
    watch: {
        taxDetails: {
            deep: true,
            handler() {
                if(!this.isLoading) {
                    this.changeMadeDetails = true
                }

            }
        },
        row: {
            deep: true,
            handler() {
                if(!this.isLoading) {
                    this.changeMadeAssingee = true
                }

            }
        }
    },
    computed: {
        editMode() {
            return this.$route.params.id;
        },
        computedNavigations() {
            return this.navigations.map((nav) => ({
                ...nav,
                title: this.$t(`manage_tax.navigation_labels.${nav.value}`),
            }))
        },
        computedColumns() {
            return this.cols.map((col) => ({
                ...col,
                name: this.$t(`manage_tax.client_column_labels.${col.label}`),
            }))
        }
    },
    async mounted() {
        this.tenantId = this.$store.getters.getTenantId;
        this.setBredCrumbs(this.currentTab);
        if (this.editMode) {
            this.isLoading = true
            this.navigations.forEach(nav => {
                nav.disabled = false;
            })
            await this.setTaxDetails()
            this.savedTax = JSON.parse(JSON.stringify(this.row))
            await this.setTenantTax()
            this.isLoading = false
        }
        else{
            await this.setClientList()
        }

    },
    methods: {
        isNumber,
        reArrange() {
            let selectedArr = [];
            let unSelectedArr = [];
            this.row.forEach((item)=> {
                if(item.tax_assigned) {
                    selectedArr.push(item)
                }
                else {
                    unSelectedArr.push(item)
                }
            })
            this.row = [...selectedArr, ...unSelectedArr]
        },
        async setClientList() {
           const {data} = await getClientList(this.tenantId)
           this.row = data
           this.row = this.row.map(rowData => {
            rowData.tax_assigned = false;
            rowData.default = true;
            return rowData
           });
        },
        handleTaxName(value) {
            if (value > 100) {
                this.taxDetails.rate = this.taxDetails.validRate
            } else if (value <= 100) {
                this.taxDetails.validRate = this.taxDetails.rate
            }
        },
        selectedTab(tab) {
            if (!tab.disabled) {
                if (this.currentTab === tab.nav) return;
                this.currentTab = tab.nav;
                this.setBredCrumbs(this.currentTab);
            }
        },
        setBredCrumbs(crumb) {
            this.customBreadCumbs = [];
            if (crumb) {
                this.customBreadCumbs.push({ name: crumb });
            }
        },
        async setTaxDetails() {
            const { data } = await getTaxDetails(this.$route.params.id)
            this.taxDetails = data
            this.taxDetails.validRate = data.rate
        },
        async saveTaxDetails(hasErrors, setNextTab) {
            this.changeMadeDetails = false
            if (hasErrors || !this.taxDetails.rate) {
                this.$toast.error(this.$t('manage_tax.required_fields_error'));
                return
            }
            try {
                let { data } = await saveTax(this.taxDetails)
                this.taxId = data.tax_id;
                this.$toast.success(this.$t('manage_tax.save_success_message'))
                //   await this.setTenantTax()
                if(data) {
                    const response = await getTenantTax(data.tax_id);
                    if (response && response?.data?.data) {
                        this.row = response?.data?.data
                        this.row = this.row?.map((res) => {
                            res.tax_id = data.tax_id
                            return res;
                        })
                    }
                    if (setNextTab) {
                        this.currentTab = setNextTab
                        this.setBredCrumbs(this.currentTab);
                        this.navigations = this.navigations.map(el => {
                            if (el.title == this.currentTab) {
                                el.disabled = false;
                            }
                            return el;
                        })

                        this.row = this.row.map(rowData => {
                            rowData.rate = this.taxDetails.rate
                            return rowData;
                        });
                    }
                }
            } catch (error) {
                console.log('err', error);
            }
        },
        async editTaxDetails(hasErrors, setNextTab) {
            this.changeMadeDetails = false
            if (hasErrors || !this.taxDetails.rate) {
            this.$toast.error(this.$t('manage_tax.required_fields_error'));
            } else {
                try {
                    if (this.$route.params.id) {
                        this.taxDetails.id = this.$route.params.id;
                        await editTaxDetails(this.taxDetails);
                        this.$toast.success(this.$t('manage_tax.update_success_message'));
                        await this.setTenantTax()
                        if (setNextTab) {
                            this.currentTab = setNextTab
                            this.row = this.row.map(rowData => {
                                if (rowData.default) {
                                    rowData.rate = this.taxDetails.rate
                                }
                                return rowData;
                            });
                        }
                    }
                } catch (error) {
                    console.log('error', error);
                }
            }
        },
        //  Assign Tenant  API Integration
        async setTenantTax() {
            try {
                const { data } = await getTenantTax(this.$route.params.id ? this.$route.params.id : this.taxId);
                if (data && data?.data) {
                    this.row = data?.data
                    this.row = this.row?.map((res) => {
                        res.tax_id = this.$route.params.id ? this.$route.params.id : this.taxId
                        return res;
                    })
                    this.reArrange();
                }
            } catch(err) {
                console.log('err', err);
            }
        },

        defaultTax(tax) {
            this.row = this.row.map((res) => {
                if (tax.default) { 
                    if ((res.client_id || tax.client_id) && res.client_id == tax.client_id) {
                        res.rate = this.taxDetails.rate
                        res.default = tax.default 
                    } else if ((res.id || tax.id) && res.id == tax.id) {
                        res.rate = this.taxDetails.rate
                        res.default = tax.default 
                    }
                } else {
                    if ((res.client_id || tax.client_id) && res.client_id == tax.client_id) {
                        res.default = tax.default
                    } else if ((res.id || tax.id) && res.id == tax.id) {
                        res.default = tax.default 
                    }
                }
                return res
            })
        },
        async assignTax() {
            try {
                let taxId = this.editMode ? this.$route.params.id : this.taxId
                let allClientList = []
                this.row.forEach(rowData => {
                    let clientData = {
                        client_id: rowData.id || rowData.client_id,
                        tax_assigned: rowData.tax_assigned,
                        rate: rowData.rate,
                        default: rowData.default
                    }
                    allClientList.push(clientData);
                });
                await assignTaxTenant(taxId, allClientList);
                this.$toast.success(this.$t('manage_tax.assign_success_message'))
                this.changeMadeAssingee = false;
            } catch (error) {
                this.changeMadeAssingee = false;
                console.log('error', error);
            }
        },
        handleSelect(selectedAll) {
            if (selectedAll) {
                this.row.forEach((res) => {
                    res.tax_assigned = true;
                });
            } else {
                this.row.forEach((res) => {
                    res.tax_assigned = false;
                });
            }
        }
    },
};
</script>
<style scoped lang="scss">
    .disableInput {
        background-color: rgb(234, 232, 232);
        cursor: not-allowed;
    }
</style>